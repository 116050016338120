














































































































































































































































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { getModule } from "vuex-module-decorators";
import linkifyHtml from "linkifyjs/html";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
const attendeeStore = getModule(attendeeVuexModule);

import GeneralCommentsModule from "@/store/vuex-modules/GeneralComments";
const commentsStore = getModule(GeneralCommentsModule);

import {
    IPageOptions,
    GeneralCommentsObjectContent,
    QuestionOfTheDay,
    AttendeeDataObject
} from "@/types/interfaces";

export default Vue.extend({
    name: "QuestionDetails",
    components: {
        PageTitleHeader,
        Spinners,
        AttendeeCard
    },
    data() {
        return {
            commentAttendeeID: "",
            labelPosition: "on-border",
            commentIsPosting: false,
            commentsInterval: 0,
            comment: "",
            comments: [] as Array<GeneralCommentsObjectContent>,
            loading: false,
            questionLoading: true,
            commentsLoading: true,
            isModalOpen: false,
            objParams: {
                id: this.qid,
                type: "Question",
                loadFrom: ""
            },
            attendeeData: {} as AttendeeDataObject,
            isFetchingAttendeeData: false
        };
    },
    props: {
        qid: {
            type: String,
            required: true,
            default: ""
        }
    },
    created() {
        this.setupPage();
    },
    updated() {
        this.masonryDisplay();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        allQuestions(): Array<QuestionOfTheDay> {
            return this.optionsStore.options.questionsOfTheDay
                ? this.optionsStore.options.questionsOfTheDay
                : [];
        },
        thisQuestion(): QuestionOfTheDay | null {
            const question = this.allQuestions.find(
                (question: QuestionOfTheDay) => question.id == this.qid
            );

            return question ? question : null;
        },
        pageOptions(): IPageOptions {
            return store.getters.getPageOptions("QuestionOfTheDay");
        },

        textHeader(): string {
            return this.pageOptions?.textHeader
                ? this.pageOptions?.textHeader
                : "";
        },
        isoLastCommentLoaded(): string {
            const comments = this.comments;
            let returnValue = "";
            let lastItem;

            if (Array.isArray(comments)) {
                lastItem = comments[comments.length - 1];
            }

            if (lastItem && lastItem?.createdTime) {
                returnValue = lastItem.createdTime;
            }

            return returnValue;
        },
        sortedComment(): Array<GeneralCommentsObjectContent> {
            return this.comments?.sort(
                (
                    curr: GeneralCommentsObjectContent,
                    nxt: GeneralCommentsObjectContent
                ) =>
                    +new Date(nxt.createdTime ? nxt.createdTime : "") -
                    +new Date(curr.createdTime ? curr.createdTime : "")
            );
        }
    },
    methods: {
        async setupPage(): Promise<void> {
            await this.setupQuestion();
            this.loadComments();
        },
        addReaction(emoji: string): void {
            if (emoji != "%E2%9D%A4%EF%B8%8F") {
                this.comment += String.fromCodePoint(Number(emoji));
            } else {
                this.comment += decodeURI(emoji);
            }
        },
        clearCommentsInterval(): void {
            window.clearInterval(this.commentsInterval);
        },
        loadComments(): void {
            this.clearCommentsInterval();

            this.objParams.loadFrom = this.isoLastCommentLoaded;

            const params = this.objParams;

            commentsStore
                .getComments(params)
                .then((res) => {
                    const x = res as Array<GeneralCommentsObjectContent>;
                    const newComments = [...this.comments, ...x];

                    this.comments = uniqWith(newComments, isEqual);
                    this.commentsInterval = window.setInterval(
                        this.loadComments,
                        5000
                    );
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.commentsLoading = false;
                });
        },
        sendComment(): void {
            if (this.commentIsPosting) return;
            this.loading = true;

            const commentObj = {
                relatedObjectId: this.qid, //static for now
                relatedObjectType: "Question", //static for now
                text: linkifyHtml (this.comment, {
                    defaultProtocol: "https",
                    attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer"
                    }})
            };

            this.clearCommentsInterval();

            this.commentIsPosting = true;
            commentsStore
                .postComment(commentObj)
                .then(() => {
                    this.loadComments();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.commentIsPosting = false;
                    this.comment = "";
                    this.loading = false;
                });
        },
        async setupQuestion(): Promise<void> {
            await this.$store.dispatch("getOptions", ["questionsOfTheDay"]);
            this.questionLoading = false;
        },
        async showModal(id: string) {
            this.commentAttendeeID = id;
            this.isModalOpen = true;
        },

        closeModal() {
            this.attendeeData = {};
            this.commentAttendeeID = "";
        },

        masonryDisplay() {
            let grids: any = [...document.querySelectorAll(".grid--masonry")];

            if (
                grids.length &&
                getComputedStyle(grids[0]).gridTemplateRows !== "masonry"
            ) {
                grids = grids.map((grid: any) => ({
                    _el: grid,
                    gap: parseFloat(getComputedStyle(grid).gridRowGap),
                    items: [...grid.childNodes].filter((c) => c.nodeType === 1),
                    ncol: 0
                }));

                grids.forEach((grid: any) => {
                    const ncol = getComputedStyle(
                        grid._el
                    ).gridTemplateColumns.split(" ").length;

                    if (grid.ncol !== ncol) {
                        grid.ncol = ncol;
                        grid.items.forEach((c: any) =>
                            c.style.removeProperty("margin-top")
                        );

                        if (grid.ncol > 1) {
                            grid.items.slice(ncol).forEach((c: any, i: any) => {
                                const prevfin = grid.items[
                                        i
                                    ].getBoundingClientRect().bottom,
                                    currIni = c.getBoundingClientRect().top;

                                c.style.marginTop = `${prevfin +
                                    grid.gap -
                                    currIni}px`;
                            });
                        }
                    }
                });
            }
        }
    },
    watch: {
        commentAttendeeID: function() {
            this.isFetchingAttendeeData = true;
            const id = this.commentAttendeeID;
            return new Promise<void>((resolve, reject) => {
                attendeeStore
                    .getAttendee({ id })
                    .then((response) => {
                        this.attendeeData = response as AttendeeDataObject;
                        return resolve();
                    })
                    .finally(() => (this.isFetchingAttendeeData = false))
                    .catch(() => {
                        // throw new Error(error);
                        // console.error("The Request is expected to fail", error);
                        // Nothing to do with the error as Request is expected to fail everytime the attendeeId is set to ""
                    });
            });
        },
        isModalOpen: function() {
            this.isModalOpen ? "" : this.closeModal();
        }
    }
});
