var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"#E5E5E5"}},[_c('PageTitleHeader',{staticClass:"h-24 lg:h-56",attrs:{"pageAccessor":'QuestionOfTheDay'}},[_c('h1',{staticClass:"mb-0 title mg-title-header text-center mg-font-bold uppercase text-lg md:text-4xl xl:text-5xl"},[_vm._v(" "+_vm._s(_vm.textHeader ? _vm.textHeader : "Question of the Day")+" ")])]),_c('div',{staticClass:"container md:py-20 w-full"},[(_vm.questionLoading)?_c('Spinners'):(!_vm.questionLoading && _vm.thisQuestion == null)?_c('p',[_vm._v(" There was a problem loading this question. ")]):_c('div',{staticClass:"grid md:grid-cols-2"},[_c('section',{staticClass:"px-4 lg:px-0 w-10/12"},[_c('h2',{staticClass:"pt-4 text-xl xl:text-2xl md:pt-0"},[_vm._v(" "+_vm._s(_vm.thisQuestion.text)+" ")])]),_c('section',{staticClass:"px-4 md:px-0"},[_c('h3',[_vm._v("Add your comments")]),_c('div',{},[_c('div',{staticClass:"field md:px-4 lg:px-0"},[_c('div',{staticClass:"control"},[_c('b-field',{attrs:{"label":"Enter comments"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('div',{staticClass:"flex flex-col lg:flex-row lg:justify-between"},[_c('div',{staticClass:"flex"},[_c('section',{staticClass:"inline-block bg-transparent"},[_c('h4',{staticClass:"text-base inline sr-only"},[_vm._v(" Add a reaction ")]),_c('b-tooltip',{attrs:{"label":"Thumbs up"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '0x0001F44D'
                                                    )}}},[_vm._v(" 👍 "),_c('span',{staticClass:"sr-only"},[_vm._v("Thumbs up Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Clap"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '0x0001F44F'
                                                    )}}},[_vm._v(" 👏 "),_c('span',{staticClass:"sr-only"},[_vm._v("Clap Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Love"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '%E2%9D%A4%EF%B8%8F'
                                                    )}}},[_vm._v(" ❤️ "),_c('span',{staticClass:"sr-only"},[_vm._v("Love Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Smile"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '0x0001F60A'
                                                    )}}},[_vm._v(" 😊 "),_c('span',{staticClass:"sr-only"},[_vm._v("Smile Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Laughing"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '0x0001F606'
                                                    )}}},[_vm._v(" 😆 "),_c('span',{staticClass:"sr-only"},[_vm._v("Laughing Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Wow"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction(
                                                        '0x0001F62E'
                                                    )}}},[_vm._v(" 😮 "),_c('span',{staticClass:"sr-only"},[_vm._v("Wow Emoji")])])]),_c('b-tooltip',{attrs:{"label":"Question"}},[_c('button',{staticClass:"bg-transparent text-lg  duration-500 transition-transform transform hover:scale-125",attrs:{"type":"button","CausesValidation":"False"},on:{"click":function($event){return _vm.addReaction('0x0002753')}}},[_vm._v(" ❓ "),_c('span',{staticClass:"sr-only"},[_vm._v("Question Emoji")])])])],1)]),_c('b-field',{staticClass:"flex w-full lg:w-auto items-center py-4 lg:py-0"},[_c('b-button',{staticClass:"w-full text-center block",attrs:{"type":"is-primary","loading":_vm.loading,"native-type":"submit"},on:{"click":function($event){return _vm.sendComment()}}},[_vm._v(" Send Comment ")])],1)],1)],1)])])])]),(_vm.commentsLoading)?_c('Spinners'):(
                !_vm.comments.length && _vm.thisQuestion != null && !_vm.commentsLoading
            )?_c('div',{staticClass:"grid m-auto py-20 text-primary text-center text-lg italic"},[_vm._v(" \"Be the first to add a comment.\" ")]):_vm._e(),_c('div',{staticClass:"grid--masonry pt-8"},_vm._l((_vm.sortedComment),function(item){return _c('section',{key:item.createdTime,staticClass:"card unset-height"},[_c('button',{staticClass:"text-lg font-bold leading-tight mb-2 font-heading px-0 card-title hover:underline bg-transparent",on:{"click":function($event){return _vm.showModal(item.attendeeId)}}},[_vm._v(" "+_vm._s(item.attendeeName)+" ")]),_c('span',{staticClass:"mb-4 block",domProps:{"innerHTML":_vm._s(item.text)}}),_c('span',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime(item.createdTime, "date-time")))])])}),0)],1),_c('b-modal',{attrs:{"active":_vm.isModalOpen,"trap-focus":"","width":400,"destroy-on-hide":""},on:{"update:active":function($event){_vm.isModalOpen=$event}}},[(_vm.isFetchingAttendeeData)?_c('Spinners'):_c('AttendeeCard',{key:_vm.commentAttendeeID,staticClass:"py-12",attrs:{"data":_vm.attendeeData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }